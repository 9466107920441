.heroimg {
  width: 100%;
  height: 100%;
}

.heroTitle {
  font-weight: 700;
  margin-top: 9%;
  color: #1a73e8;
  font-size: 270%;
  text-align: center;
}

.heroSubtitle {
  color: grey;
}

@media screen and (max-width: 460px) {
  .heroimg {
    width: 100%;
    height: 100%;
    padding-top: 20%;
  }
  .heroTitle {
    font-weight: 700;
    margin-top: 30%;
    color: #1a73e8;
    font-size: 270%;
    text-align: center;
  }
}
