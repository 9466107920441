@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");
:root {
  --font3: "Roboto", sans-serif;
}
.teamMember {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 0px 30px 0px;
}

.h1,
h1 {
  font-size: 1.5rem;
}

.container-fluid {
  max-width: 1400px;
}

.card {
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  border: 1;
  border-radius: 1rem;
}

.imgTeam {
  width: 200px;
  height: 200px;

  border-radius: 50%;
}
.list-inline {
  margin-bottom: 1px;
}

.card h5 {
  overflow: hidden;
  height: 80px;
  font-weight: 300;
  font-size: 1rem;
}

.card h5 a {
  color: black;
  text-decoration: none;
}

.role {
  color: #7a7a7a;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fab {
  font-size: 1.5rem;
  color: darkgray;
  transition: transform 0.5s;
}

.fab:hover {
  color: #1a73e8;
  transform: scale(1.1);
}

.card h2 {
  font-size: 1rem;
}

/* MEDIA */

/* @media only screen and (min-width: 1200px) {
  .img-hover-zoom--colorize img {
    width: 200px;
  }
} */

@media only screen and (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 2rem;
  }
}
.image-conatiner:hover {
  border: 3px solid #1a73e8 !important;
  transition: border-color 1s ease;
}
