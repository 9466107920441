.projects {
  color: #303030;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: auto;

  padding: 30px 0px 30px 0px;
}

.project-container {
  color: #303030;
  height: auto;
  box-shadow: 0;
  transition: box-shadow 1s;
  cursor: pointer;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  border: 1;
  border-radius: 1rem;
}
.project-container:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.github-button {
  border-radius: 50% !important;
  text-align: center;
  height: auto;
  width: 45px;
  margin-left: 6% !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  padding: 0% !important;
  transition: box-shadow 1s;
  border: none;
}
.github-button:hover {
  box-shadow: #1a73e8f6 0px 1px 1px 1px;
}
.github-button:focus {
  outline: none !important;
}
.project-description {
  align-self: center;
  text-align: left;
}
.contribute-button {
  border: none;
  background-color: #1a73e8;
  color: white;

  font-size: 18px;
  transition: box-shadow 1s;
}
.contribute-button:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
hr {
  margin: 4% !important;
}
@media screen and (max-width: 960px) {
  .projects {
    color: #303030;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: auto;
    border-bottom: #1a73e8 solid 0.5px;
    padding: 30px 0px 30px 0px;
  }
}
