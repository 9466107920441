.about {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #303030;
  padding: 20px 0px 20px 0px;
}
.aboutTitle {
  background-color: #ffffff;

}
.dscaboutsvg {
  width: 100%;
  height: 90%;
}

.aboutus-matter {
  align-self: center;
  text-align: left;
}
p {
  line-height: 28px;
  font-size: 17px;
  text-align: justify;
}

@media screen and (max-width: 760px) {
  .aboutus-matter {
    align-self: center;
    text-align: left;
    padding-top: 30px;
  }
  .about {
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom: #1a73e8 solid 0.5px;
    color: #303030;
    padding: 30px 0px 30px 0px;
  }
  .aboutus {
    height: 500px;
    margin: 50px 0px 170px 0px;
  }
}
