.motto {
  background-color: #fff;
  width: 100vw;
  height: auto;
  color: #303030;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 30px 0px 30px 0px;
}

.cards {
  width: 92%;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  border: 1;
  border-radius: 1rem;
  padding: 1.2%;
}
.cp,
.web,
.android {
  width: 100%;
  height: 180px;
  padding: 20px;
}
@media screen and (max-width: 960px) {
  .cards {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
    border: 1;
    border-radius: 1rem;
    padding: 1.5%;
  }
  .motto {
    background-color: #fff;
    width: 100vw;
    height: auto;
    color: #303030;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
     padding: 30px 0px 30px 0px;
  }
}
