.events {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 0px 30px 0px;
  border-bottom: #1a73e8 solid 0.5px;
  color: #303030;
}

.cards-event {
  width: 300px;
  height: 400px;
  padding: 0px;
  transition: box-shadow 1s;

  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  border: 1;
  border-radius: 1rem;
}
.cards-event-img {
  padding: 0px;
  width: 100%;
}
.cards-event-img-top {
  border-radius: 10px;
  width: 100%;
  height: 200px;
}
.cards-event-body {
  padding: 3%;
  text-align: left;
}


.event-container {
  position: relative;
  height: 380px;
}

.image {
  opacity: 1;
  /* display: block; */
  width: 100%;
  height: 100%;
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.event-container:hover .image {
  opacity: 0.1;
}

.event-container:hover .middle {
  opacity: 1;
}
.event-view-button{
  border: none;
  background-color: #1a73e8;
  color: white;
  padding-left: 3% !important;
  padding-right: 3% !important;
  font-size: 18px;
  transition: box-shadow 1s;
}
.event-text{
  align-self: center;
  /* text-align: left; */
}