.NavbarItems {
  background: #ffffff;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 999;
  display: flex;
  top: 0px;

  font-size: 1.2rem;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.5);
}
.dsclogo {
  height: 40px;
  float: left;
}
.navbar-logo {
  color: #1a73e8;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  font-size: 2.5rem;
}
a {
  text-decoration: none;
}
.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  font-size: 17px;
  text-align: center;
  width: 80vw;
  justify-content: end;
  margin-top: 16px;
}

.nav-links {
  color: #808080;
  text-decoration: none;
  padding: 0.5rem;
  text-align: center;
}

.nav-links:hover {
  /* background-color: #1a73e8; */
  border-radius: 4px;
  transition: all 0.2s ease-out;
  text-decoration: none;
  color: #303030;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
  margin-top: -30px;
  color: #1a73e8;
}

@media screen and (max-width: 960px) {
  .dsclogo {
    height: 40px;
    float: right;
    margin-left: -80px;
  }
  .NavbarItems {
    position: relative;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    position: absolute;
    top: 26px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #1a73e8;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-links {
    text-align: center;
    padding: 2rem;
    font-size: 1rem;
    width: 100%;
    display: table;
    color: #fff;
    text-decoration: none;
  }
  .nav-links:hover {
    background-color: #fff;
    border-radius: 0;
    color: #303030;

    text-decoration: none;
  }
  .navbar-logo {
    color: #fff;
    justify-self: normal;
    margin-left: 20px;
    cursor: pointer;
    font-size: 1.8rem;
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
  .fa-bars {
    color: #fff;
  }
  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 2.5rem;
    margin: 0rem auto;
    border-radius: 4px;
    width: 100%;
    background: #1a73e8;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }
  .nav-link-mobile:hover {
    background: #fff;
    color: #1a73e8;
    transition: 250ms;
  }
}
